import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import { Button, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteService,
  DowanloadServices,
  ServicesManagement,
  activeDeactiveService,
} from "../Redux/Actions/AdminActions";
import SearchBar from "../Common/Searchbar";
import placeholder from "../Assets/Images/Road-assistance-placehodler.png";
import * as XLSX from "xlsx";
import PaginationComponet from "../Components/Layout/Pagination";
import { toast } from "react-toastify";

export default function ServiceCategory() {
  const alldata = useSelector((state) => state.Services?.alldata);

  console.log(alldata);

  const url = process.env.REACT_APP_FILE_BASE_URL;
  const [page, setpage] = useState(1);
  const [limit, setlimit] = useState(10);
  const [flag, setflag] = useState(true);
  const [showd, setshowd] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [id, setserviceId] = useState("");
  const [activePopoverId, setActivePopoverId] = useState(null);


  const dispatch = useDispatch();
  const navigate = useNavigate();

  let location = useLocation()
  const pageNo = new URLSearchParams(location?.search).get("page");

  const handlePageChange = (pageNumber) => {
    setpage(pageNumber);
    navigate(`/service-category?page=${pageNumber}`)
       
  };

  useEffect(() => {
    const searchData = {
      page: pageNo,
      limit: limit,
      search: searchValue,
    };
    dispatch(ServicesManagement(searchData));
  }, [pageNo, limit, searchValue, flag, dispatch]);

  const fetchDataAndExportToExcel = useCallback(async () => {
    try {
      const response = await dispatch(DowanloadServices());
      console.log(response);

      const allData = response?.payload?.data?.data?.services;

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  }, [dispatch]);


  const handleDelete = () => {
    dispatch(DeleteService({ id: id }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast?.success(res?.payload?.data?.message);
          setshowd(false);
          setflag(!flag);
        }
      })
      .catch((err) => {
        console.log(err, "err Occur");
      });
  };


  const ActiveOrDeactivate = (userid, status) => {
    let formData = new FormData()
    formData.append("serviceId",userid)
    formData.append("status",status)

    dispatch(activeDeactiveService(formData))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast.success(res?.payload?.data?.message);
          setActivePopoverId(null);
          setflag(!flag);
        }
      })
      .catch((error) => {
        console.log(error, "Error occurred");
      });
  };

  const handleTogglePopover = (id) => {
    setActivePopoverId((prevId) => (prevId === id ? null : id));
  };

  const popover = (id) => {
    return (
      <Popover id="popover-basic" style={{ cursor: "pointer" }}>
        <Popover.Body>
          <p onClick={() => ActiveOrDeactivate(id, 1)}>Active</p>
          <p onClick={() => ActiveOrDeactivate(id, 0)}>InActive</p>
        </Popover.Body>
      </Popover>
    );
  };


  const handlenavigate = (id) => {
    navigate(`/edit-categories/${id}`)
  }

  return (
    <Layout>
      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="user-search">
              <div className="drop-down">
                <div className="select-box">
                  <Form.Select
                    aria-label="Default select example"
                    name="limit"
                    onChange={(e) => setlimit(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                  </Form.Select>
                </div>
              </div>
              <div className="search-tab">
                <div className="input-search">
                  <Form>
                    <Form.Group controlId="formBasicPassword">
                      <SearchBar setSearchValue={setSearchValue} />
                    </Form.Group>
                  </Form>
                </div>
                <Link to="/add-categories">
                  <div className="create-new-btn">
                    <Button type="submit">Create New</Button>
                  </div>
                </Link>
                <div className="export-box" onClick={fetchDataAndExportToExcel}>
                  <p>
                    <span>
                      <img
                        src={require("../Assets/Images/upload.svg").default}
                      />
                    </span>
                    Export
                  </p>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>
                      <div className="user-checkbox">
                        <div className="remember-check"></div>
                        Category
                      </div>
                    </th>
                    <th>Base price </th>
                    <th>Image </th>
                    <th>Status </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {alldata?.data?.services?.map((res, index) => {
                    const dynamicIndex = (pageNo - 1) * limit + (index + 1);
                    return (
                      <tr key={index}>
                        <td>{dynamicIndex}</td>
                        <td>
                          <div className="first-user">
                            <div className="remember-check"></div>
                            <div className="user-profile">
                              <div className="user-id">
                                <p>{res?.service_name || "N/A"}</p>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="user-role">
                            <p>{res?.price || "N/A"}</p>
                          </div>
                        </td>
                        <td>
                          <div className="service-images">
                            {res?.service_image ? (
                              <img src={`${url}${res?.service_image}`} />
                            ) : (
                              <img src={placeholder} />
                            )}
                          </div>
                        </td>

                        <td>
                          <div className={res?.status === 1 ? "active" : "pending"}>
                            <p>{res?.status === 1 ? "Active" : "InActive"}</p>
                          </div>
                        </td>
                        <td>
                          <div className="action">
                            <img
                              src={
                                require("../Assets/Images/delete-icon.svg")
                                  .default
                              }
                              onClick={() => {
                                setshowd(true);
                                setserviceId(res?._id);
                              }}
                              style={{ cursor: "pointer" }}
                            />
                            <img
                              src={
                                require("../Assets/Images/view-icon.svg")
                                  .default
                              }
                              onClick={() => handlenavigate(res?._id)}
                              style={{ cursor: "pointer" }}

                            />
                            <OverlayTrigger
                              trigger="click"
                              show={activePopoverId === res._id}
                              placement="bottom"
                              overlay={popover(res?._id)}
                              onToggle={() => handleTogglePopover(res._id)}
                              rootClose
                            >
                              <img
                                src={
                                  require("../Assets/Images/options.svg")
                                    .default
                                }
                                alt="Options"
                                style={{ cursor: "pointer" }}
                              />
                            </OverlayTrigger>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                  {/* <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            class="red"
                            id="filled-in-box"
                          />
                        </div>
                        <div className="user-profile">
                          <div className="user-id">
                            <p>Richard Payne</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>$20</p>
                      </div>
                    </td>
                    <td>
                      <img
                        src={require("../Assets/Images/images-op.svg").default}
                      />
                    </td>

                    <td>
                      <div className="active">
                        <p>Active</p>
                      </div>
                    </td>
                    <td>
                      <div className="action">
                        <img
                          src={
                            require("../Assets/Images/delete-icon.svg").default
                          }
                        />
                        <img
                          src={
                            require("../Assets/Images/view-icon.svg").default
                          }
                        />
                        <img
                          src={require("../Assets/Images/options.svg").default}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="first-user">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            class="red"
                            id="filled-in-box"
                          />
                        </div>
                        <div className="user-profile">
                          <div className="user-id">
                            <p>Jennifer Summers</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="user-role">
                        <p>$20</p>
                      </div>
                    </td>
                    <td>
                      <img
                        src={require("../Assets/Images/images-op.svg").default}
                      />
                    </td>

                    <td>
                      <div className="active">
                        <p>Active</p>
                      </div>
                    </td>
                    <td>
                      <div className="action">
                        <img
                          src={
                            require("../Assets/Images/delete-icon.svg").default
                          }
                        />
                        <img
                          src={
                            require("../Assets/Images/view-icon.svg").default
                          }
                        />
                        <img
                          src={require("../Assets/Images/options.svg").default}
                        />
                      </div>
                    </td>
                  </tr> */}
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p>
                    {" "}
                    Showing {1 + (pageNo - 1) * limit} -
                    {Math.min(pageNo * limit, alldata?.data?.totalCount)} of{" "}
                    {alldata?.data?.totalCount} results
                  </p>
                </div>
                <div className="pagination-block">
                  <PaginationComponet
                    currentPage={pageNo}
                    totalCount={alldata?.data?.totalCount}
                    limit={alldata?.data?.totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="made-with">
          <div className="pixinvent">
            <p>
              {" "}
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
        </div>
      </Container>

      <Modal show={showd} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h4 className="are-sure-text">
            Are you sure you want to delete this service category?
          </h4>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleDelete}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowd(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
