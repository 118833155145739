import React from "react";
import { Container } from "react-bootstrap";

const AssistsProPrivacyPolicy = () => {
  return (
    <>
      <div className="container">
        <div className="privacy_text">
          <h1>Privacy Policy </h1>
          <h2>
            Effective Date: <span>01/24/2025</span>
          </h2>
          <p>
            <b>Roadside Assist LLC </b> is committed to protecting your privacy.
            This Privacy Policy explains how we collect, use, disclose, and
            safeguard your information when you use our mobile application
            (Roadsides assist and Roadside assistpro) and services. By using the
            App, you agree to the terms of this Privacy Policy.
          </p>
          <h2>1. Information We Collect</h2>
          <p>
            We collect several types of information to provide you with our
            services, improve your experience, and ensure security. This
            includes:
          </p>
          <h4>a. Personal Information</h4>
          <div className="least">
            <ul>
              <li>Name, email address, phone number, and physical address. </li>
              <li>
                Payment information, such as credit card details or other
                payment methods, processed through secure third-party payment
                systems.
              </li>
            </ul>
          </div>
          <h4>b. Location Data </h4>
          <div className="least">
            <ul>
              <li>
                Real-time location information to enable Service Providers to
                locate and assist you.{" "}
              </li>
              <li>
                Your location history, as needed for service verification and
                analytics.
              </li>
            </ul>
          </div>
          <h4>c. Vehicle Information </h4>
          <div className="least">
            <ul>
              <li>
                Vehicle make, model, year, and condition details provided by you
                to facilitate service requests.
              </li>
            </ul>
          </div>

          <h4>d. Usage Data </h4>
          <div className="least">
            <ul>
              <li>
                Information about your use of the App, including pages viewed,
                features used, and actions taken.
              </li>
              <li>
                Device and connection information, such as IP address, browser
                type, and operating system.
              </li>
            </ul>
          </div>

          <h4>e. Communication Data </h4>
          <div className="least">
            <ul>
              <li>
                Messages, calls, or communications between you and Service
                Providers via the App.
              </li>
            </ul>
          </div>

          <h2>2. How We Use Your Information </h2>
          <p>We use the information collected for the following purposes: </p>
          <div className="least">
            <ul>
              <li>
                <b>Service Delivery:</b> To process your requests, connect you
                with Service Providers, and ensure the proper execution of
                services.{" "}
              </li>
              <li>
                <b>Account Management: </b> To create, update, and maintain your
                account.
              </li>
              <li>
                <b>Payment Processing: </b> To securely process payments and
                resolve billing issues.
              </li>
              <li>
                {" "}
                <b>Customer Support:</b> To assist with inquiries, complaints,
                or feedback.
              </li>
              <li>
                {" "}
                <b>Service Improvements: </b> To analyze usage trends and
                improve the App’s functionality, user experience, and service
                quality.
              </li>
              <li>
                {" "}
                <b>Legal Compliance: </b> To comply with legal obligations or
                enforce our Terms and Conditions.
              </li>
            </ul>
          </div>

          <h2>3. How We Share Your Information </h2>
          <p>We may share your information with third parties as follows:</p>

          <h4>a. Service Providers </h4>
          <p>
            Your information will be shared with independent contractors who
            provide roadside assistance or towing services. This includes your
            name, contact details, and location to enable them to fulfill your
            service request.{" "}
          </p>
          <h4>b. Payment Processors </h4>
          <p>
            We use secure third-party payment systems to process payments. Your
            payment information is shared only as necessary for completing
            transactions.{" "}
          </p>

          <h4>c. Legal and Regulatory Authorities </h4>
          <p>
            We may disclose your information if required by law, regulation, or
            legal process, or to protect our rights and the safety of others.{" "}
          </p>

          <h4>d. Business Transfers </h4>
          <p>
            In the event of a merger, acquisition, or sale of our company, your
            information may be transferred to the new entity.{" "}
          </p>

          <h2>4. Data Security </h2>
          <p>
            We take the protection of your data seriously and implement the
            following security measures:{" "}
          </p>
          <div className="least">
            <ul>
              <li>
                <b>Encryption:</b> All sensitive data, such as payment and
                location information, is encrypted during transmission.
              </li>
              <li>
                <b> Access Controls: </b> Access to your personal data is
                restricted to authorized personnel only.
              </li>

              <li>
                <b> Monitoring: </b> We regularly monitor our systems for
                vulnerabilities and breaches.{" "}
              </li>
            </ul>
          </div>
          <p>
            Despite our efforts, no system can guarantee 100% security. Please
            notify us immediately if you suspect unauthorized access to your
            account.{" "}
          </p>

          <h2>5. Your Privacy Choices</h2>

          <p>
            You have the following rights regarding your personal information:{" "}
          </p>

          <h4>a. Access and Update </h4>
          <p>
            You can access, update, or correct your personal information at any
            time through the App’s account settings.
          </p>
          <h4>b. Opt-Out </h4>
          <p>
            You may opt out of receiving promotional communications by following
            the instructions in the communication or contacting us directly.
          </p>

          <h4>c. Location Data </h4>
          <p>
            You can disable location tracking in your device settings, though
            this may limit the functionality of the App.
          </p>

          <h4>d. Data Deletion </h4>
          <p>
            You may request the deletion of your personal information by
            contacting us at the email provided below. Note that we may retain
            certain data as required by law or for legitimate business purposes.
          </p>

          <h2>6. Retention of Data </h2>
          <p>
            We retain your personal information only for as long as necessary to
            provide our services, comply with legal obligations, resolve
            disputes, and enforce agreements.{" "}
          </p>

          <h2>7. Third-Party Links </h2>
          <p>
            Our App may contain links to third-party websites or services. We
            are not responsible for the privacy practices or content of these
            third parties. Please review their privacy policies before providing
            any personal information.{" "}
          </p>
          <h2>8. Children’s Privacy </h2>
          <p>
            The App is not intended for children under the age of 18, and we do
            not knowingly collect personal information from minors. If we become
            aware of data collected from a child, we will delete it promptly.
          </p>
          <h2>9. Changes to This Privacy Policy </h2>
          <p>
            We may update this Privacy Policy from time to time. Any changes
            will be communicated through the App or via email. Your continued
            use of the App after the changes take effect constitutes your
            acceptance of the revised Privacy Policy.
          </p>

          <h2>10. Contact Us </h2>
          <p>
            If you have any questions, concerns, or requests regarding this
            Privacy Policy, please contact us at:
          </p>

          <h2>Roadside Assist LLC Customer Support </h2>
          <p>
            Email:{" "}
            <a href="mailto:roadsideassistllc@gmail.com ">
              roadsideassistllc@gmail.com
            </a>{" "}
          </p>
        </div>
      </div>
    </>
  );
};

export default AssistsProPrivacyPolicy;
