import React from "react";
import { Container } from "react-bootstrap";

const AssistsTermsAndConditions = () => {
  return (
    <>
      <div className="container">
        <div className="privacy_text">
          <h1>Roadside Assist LLC Terms and Conditions</h1>
          <h2>
            Effective Date: <span>01/24/2025</span>
          </h2>
          <p>
            Welcome to <b>Roadside Assist LLC! </b> These Terms and Conditions
            govern your use of the <b>Roadside Assist LLC </b> mobile
            applications (Roadsideassist and roadsidesassistpro) and the
            services provided through it. By requesting tow services or roadside
            services through the App, you agree to these Terms. Please read them
            carefully.
          </p>

          <h2>1. Services Provided </h2>
          <p>
            <b> Roadside Assist LLC </b> offers users the ability to request
            towing and roadside mechanic, and other roadside vehicle services
            through our App. These services are provided by independent
            contractors (“Service Providers”) who are not employees of{" "}
            <b>Roadside Assist LLC. Roadside Assist LLC </b> acts solely as a
            platform to connect users with Service Providers.
          </p>

          <h2>2. Eligibility to Use the App </h2>

          <p>To use the App and request services, you must:</p>
          <div className="least">
            <ul>
              <li>Be at least 18 years old. </li>
              <li>
                Provide accurate, current, and complete information during the
                account registration process.{" "}
              </li>
              <li>Comply with all applicable laws and regulations. </li>
              <li>Have a valid payment method linked to your account. </li>
            </ul>
          </div>

          <h2>3. Requesting Services </h2>

          <p>
            When you submit a request for tow or roadside mechanic services:{" "}
          </p>
          <div className="least">
            <ul>
              <li>
                The Service Provider will use commercially reasonable efforts to
                respond to your request promptly.{" "}
              </li>
              <li>
                Service Providers may contact you directly through the App or
                via phone to confirm service details.
              </li>
              <li>
                The Company does not guarantee that a Service Provider will
                always be available, and wait times may vary depending on your
                location and service demand.{" "}
              </li>
            </ul>
          </div>

          <h2>4. Service Fees and Payment </h2>

          <div className="least">
            <ul>
              <li>
                Service fees for towing and roadside assistance will be
                displayed in the App prior to confirming your request.
              </li>
              <li>
                By confirming the request, you authorize{" "}
                <b>Roadside Assist LLC </b>to charge your linked payment method
                for the applicable fees.
              </li>
              <li>
                Additional fees may apply if your service request exceeds the
                scope of the standard roadside assistance or towing (e.g., extra
                mileage, complex mechanical work). Any such additional fees will
                be disclosed.
              </li>
            </ul>
          </div>

          <h2>5. Cancellations and No-Show Policy</h2>

          <div className="least">
            <ul>
              <li>
                You may cancel a service request without penalty if done before
                a Service Provider has been dispatched.
              </li>
              <li>
                If a cancellation is made after the Service Provider is en
                route, or if you are not present at the location, a cancellation
                fee may apply.
              </li>
              <li>
                In cases where a Service Provider is unable to fulfill your
                request (e.g., due to incorrect information provided),{" "}
                <b>Roadside Assist LLC </b> reserves the right to charge a
                no-show fee.
              </li>
            </ul>
          </div>

          <h2>6. Customer Responsibilities </h2>

          <p>You agree to:</p>

          <div className="least">
            <ul>
              <li>
                Provide accurate information regarding your location and vehicle
                condition.
              </li>
              <li>
                Ensure that the vehicle is in a safe and accessible area for the
                Service Provider.
              </li>
              <li>Treat Service Providers with respect and professionalism.</li>

              <li>Comply with all traffic laws while awaiting assistance. </li>

              <li>
                Assume responsibility for any personal belongings left in the
                vehicle during towing or repair services.{" "}
              </li>
            </ul>
          </div>

          <h2>7. Limitations of Liability </h2>
          <div className="least">
            <ul>
              <li>
                <b>Roadside Assist LLC</b> facilitates connections between you
                and independent Service Providers. The Company is not
                responsible for the quality, safety, or legality of the services
                provided by the Service Providers.
              </li>
              <li>
                <b>Roadside Assist LLC</b> shall not be liable for any
                incidental, special, or consequential damages, including but not
                limited to lost time, damage to property, or costs related to
                alternative transportation.
              </li>

              <li>
                While <b>Roadside Assist LLC</b> makes efforts to work with
                qualified Service Providers, we do not provide any warranties or
                guarantees regarding their services.
              </li>
            </ul>
          </div>

          <h2>8. Indemnification </h2>

          <p>
            You agree to indemnify, defend, and hold harmless{" "}
            <b>Roadside Assist LLC</b>, its affiliates, employees, and agents
            from any and all claims, damages, losses, liabilities, or expenses
            arising out of or related to your use of the App or the services
            provided by Service Providers.
          </p>

          <h2>9. Dispute Resolution </h2>

          <p>
            If you have a dispute with a Service Provider regarding services
            received:
          </p>

          <div className="least">
            <ul>
              <li>
                You may contact <b>Roadside Assist LLC</b> customer support
                within 48 hours of receiving service for resolution assistance.
              </li>
              <li>
                <b>Roadside Assist LLC</b> will mediate disputes but cannot
                guarantee resolution.
              </li>

              <li>
                Any disputes arising out of or relating to these Terms or the
                services provided shall be resolved through binding arbitration
                in accordance with the rules of the American Arbitration
                Association (AAA).
              </li>
            </ul>
          </div>

          <h2>10. Termination of Services </h2>

          <p>
            <b>Roadside Assist LLC</b> reserves the right to suspend or
            terminate your access to the App and services if you:
          </p>

          <div className="least">
            <ul>
              <li>Violate these Terms.</li>
              <li>Engage in fraudulent, abusive, or unlawful activity.</li>

              <li>Fail to pay any applicable fees.</li>
            </ul>
          </div>

          <h2>11. Force Majeure </h2>

          <p>
            <b> Roadside Assist LLC </b> is not liable for any failure to
            provide services due to circumstances beyond its control, including
            but not limited to natural disasters, weather conditions, or road
            closures.
          </p>

          <h2>12. Modification of Terms</h2>

          <p>
            <b> Roadside Assist LLC </b> reserves the right to modify these
            Terms at any time. Any changes will be communicated through the App
            or via email. Your continued use of the App after such modifications
            constitutes your acceptance of the revised Terms.
          </p>

          <h2>13. Governing Law </h2>

          <p>
            These Terms shall be governed by and construed in accordance with
            the laws of Maryland in the USA, without regard to its conflict of
            law principles.
          </p>

          <h2>14. Contact Information </h2>

          <p>
            For any questions, complaints, or claims regarding these Terms or
            the services provided, please contact us at:
          </p>
          <p>
            <b>Roadside Assist LLC Customer Support </b>
          </p>

          <p>
            Email:{" "}
            <a href="mailto:roadsideassistllc@gmail.com">
              roadsideassistllc@gmail.com
            </a>{" "}
          </p>
        </div>
      </div>
    </>
  );
};

export default AssistsTermsAndConditions;
